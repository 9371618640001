import { ReactChild, ReactChildren } from "react";

interface IFieldGroup {
  children?: ReactChild | ReactChild[] | ReactChildren | ReactChildren[];
}

const FieldGroup = ({ children }: IFieldGroup) => {
  return <div className="FieldGroup">{children}</div>;
};

export default FieldGroup;
