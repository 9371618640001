export enum DeviceTypes {
  LIGHTNING = "Lighting",
  HEATING = "Heat",
  SHUTTER = "Shutter",
}

export interface QuestionDefinition {
  answerCount?: number;
  icons?: string[];
  questionValue: string;
  question_details: "no" | "yes";
  question_details_image: string;
  question_details_text?: string;
  question_type: QuestionType;
  tile_image: any;
  tile_type: "horizontal" | "vertical";
}

export enum QuestionType {
  SIMPLE_SELECT = "SIMPLE_SELECT",
  TILE = "tile",
}
