import React, { FC, ReactNode } from "react";
import "./styles.scss";

interface CardProps {
  disabled?: boolean;
  subtitle?: string;
  title: string | ReactNode;
}

const Card: FC<CardProps> = ({ children, disabled, title, subtitle }) => {
  return (
    <div className={`Card ${disabled ? "Card-disabled" : ""}`}>
      {!!title && <h4 className="Card-title">{title}</h4>}
      {!!subtitle && <h6 className="Card-subtitle">{subtitle}</h6>}
      <div className="Card-content">{children}</div>
    </div>
  );
};

export default Card;
