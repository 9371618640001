import { useState } from "react";
import Card from "../../../Card";
import SimpleSelect from "./components/SimpleSelect";
import TileSelect from "./components/TileSelect";
import { QuestionDefinition, QuestionType } from "../../../../shared/types";

import "./styles.scss";

interface QuestionProps {
  type?: QuestionType;
  title: string;
  options: string[];
  disabled: boolean;
  onAnswer: (answer: string) => void;
  definition: QuestionDefinition;
}

const getQuestionComponentByType = (type?: QuestionType) => {
  debugger;
  switch (type as QuestionType) {
    case QuestionType.TILE:
      return TileSelect;
    default:
      return SimpleSelect;
  }
};

const Question = ({
  title,
  type,
  options,
  disabled,
  definition,
  onAnswer,
}: QuestionProps) => {
  const [selectedAnswer, setSelectedAnswer] = useState("");

  const handleAnswer = (option: string) => {
    setSelectedAnswer(option);
    onAnswer(option);
  };

  const QuestionComponent = getQuestionComponentByType(
    definition?.question_type
  );

  return (
    <Card disabled={disabled} title={title}>
      <QuestionComponent
        answer={selectedAnswer}
        options={options}
        handleAnswer={handleAnswer}
        definition={definition}
      />
    </Card>
  );
};

export default Question;
