import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import "./styles.scss";

import { RecommendedProduct } from "./components";
import AppContext from "../../context/AppContext";
import axios from "axios";
import { DeviceTypes } from "../../shared/types";
import { Card, FieldGroup, FieldLabel, FieldValue } from "..";

interface IResult {
  type?: string;
  deviceTypes?: string;
  answeredQuestions: any[];
}

interface BOMContainerProps {
  step: number;
  setNextStep: () => void;
  setPreviousStep: () => void;
  results: IResult[];
}

const recommendedProducts = [
  {
    title: "SCHNEIDER ELECTRIC EER31800 Wiser IP Modul, 230V AC",
    imageUrl:
      "https://webshop.unielektro.de/dasd/cv/media/28f52e5dab88b7c3259bb5a0d8e16f70/item-medium/JG95UJL3MQGJABBT4LALA7B3R4.jpg",
    reference: "01933014",
  },
  {
    title: "SCHNEIDER ELECTRIC VW3A7704 Bremswiderstand - IP 20 - 15 Oh",
    imageUrl:
      "https://oxomi.com/media/TL4B65K7V99SB09DATN9DMNFD8.jpg?accessToken=267b6caef256011ef685b2a473119ab4",
    reference: "01573041",
  },
  {
    title: "SCHNEIDER ELECTRIC EER31800 Wiser IP Modul, 230V AC",
    imageUrl:
      "https://webshop.unielektro.de/dasd/cv/media/28f52e5dab88b7c3259bb5a0d8e16f70/item-medium/JG95UJL3MQGJABBT4LALA7B3R4.jpg",
    reference: "01933014",
  },
];

interface IProduct {
  id: string;
  imageUrl: string;
  price: string;
  quantity: number;
  title?: string;
  totalPrice: string;
  url: string;
}

interface IDevice {
  deviceType: DeviceTypes;
  results: IProduct[];
  totalPrice: string;
  totalQuantity: number;
}

interface IBomState {
  devices: IDevice[];
  totalPrice: string;
  totalQuantity: number;
}

const initialBomState: IBomState = {
  devices: [],
  totalQuantity: 0,
  totalPrice: "0",
};

const BOMContainer = ({ step }: BOMContainerProps) => {
  const [t] = useTranslation();
  const appState = useContext(AppContext);
  const [bomState, setBomState] = useState<IBomState>(initialBomState);
  console.log(appState.results);

  useEffect(() => {
    axios
      .post("https://wiser-api.sesmartconfig.com/report/bom", {
        deviceTypes: appState.results.map((result: IResult) => ({
          type: result.deviceTypes,
          answeredQuestions: result.answeredQuestions,
        })),
        livingRooms: 1,
        bedRooms: 1,
      })
      .then((res) => res.data)
      .then((data: IBomState) => setBomState(data));

    return () => {};
  }, [appState.results]);

  console.log(bomState);

  const shutterProduct: IDevice | undefined = bomState?.devices.find(
    (x) => x.deviceType === DeviceTypes.SHUTTER
  );

  const lightingProduct: IDevice | undefined = bomState?.devices.find(
    (x) => x.deviceType === DeviceTypes.LIGHTNING
  );

  return (
    <div className="BOMContainer">
      <div className="title">{t("bom.selectedProductsTitle")}</div>
      <div className="Selection">
        <Card title={t("")}>
          <FieldGroup>
            <FieldLabel value={t("Choose Property type")} />
            <FieldValue value={appState.layoutType} />
          </FieldGroup>
          <FieldGroup>
            <FieldLabel value={t("Property Layout")} />
            <FieldValue value={appState.propertyType} />
          </FieldGroup>
          <FieldGroup>
            <FieldLabel value={t("Device type")} />
            <>
              {bomState.devices.map((dt) => (
                <FieldValue value={dt.deviceType} />
              ))}
            </>
          </FieldGroup>
          {appState.results.map((res: any) => {
            return Object.keys(res.answeredQuestions).map((key) => {
              return (
                <FieldGroup>
                  <FieldLabel value={key} />
                  <FieldValue value={res.answeredQuestions[key]} />
                </FieldGroup>
              );
            });
          })}
        </Card>
        {shutterProduct &&
          shutterProduct?.results.map((x: IProduct) => {
            return (
              <Card title="" subtitle={t("Heating")}>
                <div className="BOM__Product">
                  <div className="BOM__Product__Image">
                    <img src={x.imageUrl} />
                  </div>
                  <div className="BOM__Product__Title">{x.title}</div>
                  <div className="BOM__Product__Reference">{x.id}</div>
                  <div className="BOM__Product__Description">{x.title}</div>
                  <div className="BOM__Product__Quantity">{x.quantity}</div>
                </div>
              </Card>
            );
          })}
        {lightingProduct &&
          lightingProduct?.results.map((x: IProduct) => {
            return (
              <Card title="" subtitle={t("Heating")}>
                <div className="BOM__Product">
                  <div className="BOM__Product__Image">
                    <img src={x.imageUrl} />
                  </div>
                  <div className="BOM__Product__Title">{x.title}</div>
                  <div className="BOM__Product__Reference">{x.id}</div>
                  <div className="BOM__Product__Description">{x.title}</div>
                  <div className="BOM__Product__Quantity">{x.quantity}</div>
                </div>
              </Card>
            );
          })}
      </div>
      <div className="title">{t("bom.recommendedProducts")}</div>
      <div className="RecommendedProducts">
        {recommendedProducts.map((rp) => {
          return (
            <RecommendedProduct
              title={rp.title}
              reference={rp.reference}
              imageUrl={rp.imageUrl}
            />
          );
        })}
      </div>
    </div>
  );
};

export default BOMContainer;
