import { DeviceTypes } from "../shared/types";

export enum ActionKind {
  SetDeviceTypes = "SET_DEVICE_TYPES",
  PushResult = "PUSH_RESULTS",
  SetNextActiveDevice = "SET_NEXT_ACTIVE_DEVICE",
  SetPropertType = "SET_PROPERTY_TYPE",
  SetLayoutType = "SET_LAYOUT_TYPE",
}

export type Action = {
  type: ActionKind;
  payload?: string | DeviceTypes[] | any;
};

const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case ActionKind.SetDeviceTypes:
      return { ...state, deviceTypes: action.payload };
    case ActionKind.PushResult:
      return { ...state, results: [...state.results, action.payload] };
    case ActionKind.SetLayoutType:
      return { ...state, layoutType: action.payload };
    case ActionKind.SetPropertType:
      return { ...state, propertyType: action.payload };
    case ActionKind.SetNextActiveDevice:
      const deviceTypes = state.deviceTypes;
      const activeDeviceType = deviceTypes.pop();

      return { ...state, deviceTypes: [...deviceTypes], activeDeviceType };
    default:
      return state;
  }
};

export default reducer;
