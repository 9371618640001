import { useTranslation } from 'react-i18next';

import './styles.scss';

const AppHeader = function () {
  const { t, i18n } = useTranslation( );

  return(
    <div className="AppHeader">
        <div className="logo">
            <img src="/assets/schneider_logo.png" alt="Schneider logo" />
        </div>
        <div className="right">
          <select onChange={(e) => i18n.changeLanguage(e.target.value)}>
            <option value="en">English</option>
            <option value="fr">Français</option>
        </select>
        <a href="http://www.se.com">{t('header.contactSupport')}</a>
      </div>
    </div>
  );
};

export default AppHeader;
