import { useTranslation } from "react-i18next";

import "./styles.scss";

interface IRecommendedProductProps {
  title: string;
  reference: string;
  imageUrl: string;
}

const RecommendedProduct = ({
  title,
  reference,
  imageUrl,
}: IRecommendedProductProps) => {
  const [t] = useTranslation();

  return (
    <div className="RecommendedProduct">
      <div className="RecommendedProduct__Image">
        <img src={imageUrl} />
      </div>
      <div className="RecommendedProduct__Title">{title}</div>
      <div className="RecommendedProduct__Reference">{reference}</div>
      <div className="RecommendedProduct__Button">
        <button>{t("bom.add")}</button>
      </div>
    </div>
  );
};

export default RecommendedProduct;
