import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'

import { RoomItem } from './components'
import { IRoomState, RoomDefinition } from '../../../../../../models'

import * as RoomImages from './assets';

import './styles.scss';

interface RoomModalProps {
  isOpen: boolean;
  onRequestClose(): void;
  onValidate(roomsState: IRoomState): void;
}

const Rooms = [
  {
    title: 'shared.roomTypes.bathroom',
    image: RoomImages.Bathroom,
    key: 'bathrooms',
  },
  {
    title: 'shared.roomTypes.kitchen',
    image: RoomImages.Kitchen,
    key: 'cuisines',
  },
  {
    title: 'shared.roomTypes.toilet',
    image: RoomImages.Wc,
    key: 'toilet',
  },
  {
    title: 'shared.roomTypes.livingRoom',
    image: RoomImages.LivingRoom,
    key: 'livingRoom',
  },
  {
    title: 'shared.roomTypes.bedroom',
    image: RoomImages.Bedroom,
    key: 'bedrooms',
  },
  {
    title: 'shared.roomTypes.office',
    image: RoomImages.Office,
    key: 'office',
  },
  {
    title: 'shared.roomTypes.outside',
    image: RoomImages.Outside,
    key: 'outside',
  },
];

const getInitialRoomState = () => {
  const state: IRoomState = {};
  Rooms.forEach((room: RoomDefinition) => {
    state[room.key] = 0;
  });

  return state;
};

const RoomModal = ({ isOpen, onRequestClose, onValidate }: RoomModalProps) => {
  const [t] = useTranslation();
  const [roomsState, setRoomsState] = useState(getInitialRoomState());

  useEffect(() => {
    setRoomsState(getInitialRoomState());
  }, [isOpen]);
  const handleItemDecrease = (key: string): void => {
    if (roomsState[key] === 0) {
      return;
    }
    roomsState[key] = roomsState[key] - 1;
    setRoomsState({ ...roomsState });
  };

  const handleItemIncrease = (key: string): void => {
    roomsState[key] = roomsState[key] + 1;
    setRoomsState({ ...roomsState });
  };

  const handleValidateClick = () => {
    onValidate(roomsState);
  };

  return (
    <Modal id="RoomModal" isOpen={isOpen}>
      <div className="RoomModal__Header">
        <div className="RoomModal__Header__Title">
          {t('homeContainer.roomModal.title')}
        </div>
        <div className="RoomModal__Header__Right">
          <button onClick={onRequestClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="27"
              height="27"
              viewBox="0 0 27 27"
            >
              <g id="Group_2" data-name="Group 2" transform="translate(0 27)">
                <path
                  id="Path_2"
                  data-name="Path 2"
                  d="M3.938-23.062A13.019,13.019,0,0,1,13.5-27a13.019,13.019,0,0,1,9.563,3.938A13.019,13.019,0,0,1,27-13.5a13.019,13.019,0,0,1-3.937,9.563A13.019,13.019,0,0,1,13.5,0,13.019,13.019,0,0,1,3.938-3.937,13.019,13.019,0,0,1,0-13.5,13.019,13.019,0,0,1,3.938-23.062Zm14.625,13.5L14.555-13.5l4.008-3.937a.71.71,0,0,0-.035-1.09.71.71,0,0,0-1.09-.035L13.5-14.555,9.563-18.562a.71.71,0,0,0-1.09.035.71.71,0,0,0-.035,1.09L12.445-13.5,8.438-9.562v.07a.688.688,0,0,0,0,1.055h.07a.688.688,0,0,0,1.055,0L13.5-12.445l3.938,4.008h.07a.688.688,0,0,0,1.055,0v-.07A.688.688,0,0,0,18.563-9.562Z"
                  fill="#909090"
                />
              </g>
            </svg>
          </button>
        </div>
      </div>
      <div className="RoomModal__Body">
        <div className="RoomItemsContainer">
          <div className="RoomItems">
            {Rooms.map((room) => (
              <RoomItem
                key={room.key}
                title={room.title}
                image={room.image}
                roomKey={room.key}
                onIncrease={handleItemIncrease}
                onDecrease={handleItemDecrease}
                quantity={roomsState[room.key]}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="RoomModal__Controls">
        <button
          onClick={handleValidateClick}
          className="Button Button--Primary"
        >
          {t('general.validate')}
        </button>
      </div>
    </Modal>
  );
};

export default RoomModal;
