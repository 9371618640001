import {
  ChangeEvent,
  FC,
  useState,
  useEffect,
  useReducer,
  Reducer,
} from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";
import StepContainer from "../StepContainer";
import axios from "axios";
import Card from "../Card";
import GlobalLoader from "../GlobalLoader";
import { Question } from "./components";
import { DeviceTypes } from "../../shared/types";

interface QuizContainerProps {
  step: number;
  setNextStep: () => void;
  setPreviousStep: () => void;
  deviceTypes: DeviceTypes[];
  setDeviceTypes: (deviceTypes: DeviceTypes[]) => void;
  pushSelection: (result: any) => void;
  setNextActivedeviceType: () => void;
  // activeDeviceType: string;
}

interface IReducerState {
  activeDeviceType?: string;
  currentQuestionNumber: number;
  questions: any[];
  isLoading: boolean;
}

const initialState: IReducerState = {
  activeDeviceType: undefined,
  currentQuestionNumber: 0,
  questions: [],
  isLoading: false,
};

enum ReducerActions {
  GET_QUESTION = "GET_QUESTION",
  ADD_QUESTION = "ADD_QUESTION",
  REMOVE_QUESTION = "REMOVE_QUESTION",
  SET_QUESTION_ANSWERED = "SET_QUESTION_ANSWERED",
  SET_ACTIVE_DEVICE_TYPE = "SET_ACTIVE_DEVICE_TYPE",
  RESET = "RESET",
}

interface IReducerAction {
  type: ReducerActions;
  payload?: any;
}

function init(): IReducerState {
  return {
    questions: [],
    activeDeviceType: undefined,
    isLoading: false,
    currentQuestionNumber: 0,
  };
}

function reducer(state: IReducerState, action: IReducerAction): IReducerState {
  switch (action.type) {
    case ReducerActions.GET_QUESTION:
      return { ...state, isLoading: true };
    case ReducerActions.ADD_QUESTION:
      return {
        ...state,
        currentQuestionNumber: state.currentQuestionNumber + 1,
        isLoading: false,
        questions: [...state.questions, action.payload],
      };
    case ReducerActions.REMOVE_QUESTION:
      state.questions.pop();
      return {
        ...state,
        currentQuestionNumber: state.questions.length - 1,
        questions: [...state.questions],
      };
    case ReducerActions.SET_QUESTION_ANSWERED:
      const index = state.questions.findIndex(
        (q) => q.questionValue === action.payload.questionValue
      );
      state.questions[index].answer = action.payload.answer;
      return { ...state };
    case ReducerActions.SET_ACTIVE_DEVICE_TYPE:
      return {
        ...state,
        questions: [],
        activeDeviceType: action.payload,
        isLoading: false,
        currentQuestionNumber: 0,
      };
    case ReducerActions.RESET:
      return init();
    default:
      throw new Error();
  }
}

const areAllQuestionsAnswered = (questions: any[]) => {
  return questions.reduce((prev, current) => {
    return prev && !!current.answer;
  }, true);
};

const mapPayload = (questions: any[]) => {
  return questions.reduce((prev, current) => {
    prev[current.questionValue] = current.answer;
    return prev;
  }, {});
};

const QuizContainer: FC<QuizContainerProps> = ({
  step,
  setNextStep,
  deviceTypes,
  setDeviceTypes,
  pushSelection,
  setPreviousStep,
}) => {
  const [
    { currentQuestionNumber, questions, activeDeviceType, isLoading },
    dispatch,
  ] = useReducer(reducer, initialState, init);

  // const [results, setResults] = useState<any[]>([]); // define state

  const popDeviceType = () => {
    const deviceType = deviceTypes.pop();
    dispatch({
      type: ReducerActions.SET_ACTIVE_DEVICE_TYPE,
      payload: deviceType,
    });
    setDeviceTypes(deviceTypes);
  };

  useEffect(() => {
    popDeviceType();
  }, []);

  useEffect(() => {
    fetchNewQuestion();
  }, [activeDeviceType]);

  const fetchNewQuestion = () => {
    dispatch({ type: ReducerActions.GET_QUESTION });
    return axios
      .post("https://wiser-api.sesmartconfig.com/question", {
        deviceTypes: [
          {
            type: activeDeviceType,
            answeredQuestions: mapPayload(questions),
          },
        ],
      })
      .then((res) => {
        const { finished, nextQuestion } = res.data[0];
        if (finished) {
          // const newResults =[
          //   ...results,
          //   {
          //     deviceTypes: activeDeviceType,
          //     answeredQuestions: mapPayload(questions),
          //   },
          // ];
          // setResults(newResults);
          pushSelection({
            deviceTypes: activeDeviceType,
            answeredQuestions: mapPayload(questions),
          });
          if (deviceTypes.length > 0) {
            popDeviceType();
          } else {
            setNextStep();
          }
        } else {
          dispatch({
            type: ReducerActions.ADD_QUESTION,
            payload: nextQuestion,
          });
        }
        // check finished
      });
  };

  const handleNext = () => {
    return () => {
      fetchNewQuestion();
    };
  };

  const handleBack = () => {
    return () => {
      if (questions.length === 1) {
        setPreviousStep();
      }
      dispatch({ type: ReducerActions.REMOVE_QUESTION });
    };
  };

  //   const canProgress = (currentStep === 0 && !!propertyType) ||
  //     (currentStep === 1 && true) ||
  //     (currentStep === 2 && !!selectedDeviceTypes.length)
  const canProgress = areAllQuestionsAnswered(questions);

  const setQuestionAnswered = (questionValue: string) => (answer: string) => {
    dispatch({
      type: ReducerActions.SET_QUESTION_ANSWERED,
      payload: { questionValue, answer },
    });
  };

  return (
    <>
      <StepContainer
        canProgress={canProgress}
        onBack={handleBack()}
        onNext={handleNext()}
      >
        <div className="QuizContainer">
          {/* <div>Active device type: {activeDeviceType}</div> */}
          <div className="Container">
            {questions.map(
              ({ questionValue, answers, answer, questionType }, i) => {
                return (
                  <Question
                    title={questionValue}
                    options={answers}
                    definition={questionType}
                    disabled={i < currentQuestionNumber - 1}
                    onAnswer={setQuestionAnswered(questionValue)}
                  />
                );
              }
            )}
          </div>
        </div>
      </StepContainer>
      {isLoading && <GlobalLoader />}
    </>
  );
};

export default QuizContainer;
