import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { RoomListItem, RoomModal, CustomLayoutSelection } from "./components";
import { Card } from "../../..";
import HouseIcon from "../../assets/house.png";
import { RoomListTypes } from "../../../../models";
import { Info } from "../../../icons";
import { RoomListDefinition } from "./types";
import "./styles.scss";

interface PropertyLayoutStepProps {
  disabled: boolean;
  onSelect: (rld: RoomListDefinition) => void;
}

const RoomListDefinitions: RoomListDefinition[] = [
  {
    title: "T1",
    subtitle: "type",
    bathrooms: 1,
    cuisines: 1,
    toilet: 1,
    livingRoom: 1,
    bedrooms: 0,
    homeSolution: 1,
    outside: 0,
    kitchen: 0,
    office: 0,
  },
  {
    title: "T2",
    subtitle: "type",
    bathrooms: 1,
    cuisines: 1,
    toilet: 1,
    livingRoom: 1,
    bedrooms: 1,
    homeSolution: 1,
    outside: 0,
    kitchen: 0,
    office: 0,
  },
  {
    title: "T3",
    subtitle: "type",
    bathrooms: 1,
    cuisines: 1,
    toilet: 1,
    livingRoom: 1,
    bedrooms: 2,
    homeSolution: 1,
    outside: 0,
    kitchen: 0,
    office: 0,
  },
  {
    title: "T4",
    subtitle: "type",
    bathrooms: 1,
    cuisines: 1,
    toilet: 1,
    livingRoom: 1,
    bedrooms: 3,
    homeSolution: 1,
    outside: 0,
    kitchen: 0,
    office: 0,
  },
];

const PropertyLayoutStep: FC<PropertyLayoutStepProps> = ({
  disabled,
  onSelect,
}) => {
  const [t] = useTranslation();
  const [isLayoutModalOpen, setLayoutModalState] = useState(false);
  const [isCustomLayoutSelected, setCustomLayoutSelected] = useState(false);
  const [selectedRooms, setSelectedRooms] = useState(null);
  const [cardPropertyLayoutMode, setCardPropertyLayoutMode] = useState(
    RoomListTypes.DEFAULT
  );

  const [selectedLayout, setSelectedLayout] = useState<RoomListDefinition>();

  const handleLayoutToggleClick = useCallback(() => {
    setCardPropertyLayoutMode(
      cardPropertyLayoutMode === RoomListTypes.DEFAULT
        ? RoomListTypes.DETAILS
        : RoomListTypes.DEFAULT
    );
  }, [cardPropertyLayoutMode]);

  const toggleLayoutModal = useCallback(() => {
    if (isCustomLayoutSelected) {
      return;
    }

    setLayoutModalState(!isLayoutModalOpen);
  }, [isLayoutModalOpen]);

  const handleModalConfirmation = (roomState: any) => {
    console.log(roomState);
    toggleLayoutModal();
    setCustomLayoutSelected(true);
    setSelectedRooms(roomState);
    onSelect(roomState);
  };

  const onCustomLayoutReset = () => {
    setSelectedRooms(null);
    setCustomLayoutSelected(false);
  };

  return (
    <>
      <Card
        disabled={disabled}
        title={
          <>
            {t("homeContainer.choosePropertyLayout")}
            <button
              className="Button Button--Details"
              onClick={handleLayoutToggleClick}
            >
              <Info />
            </button>
          </>
        }
      >
        <div className="card-list-container">
          {isCustomLayoutSelected && (
            <CustomLayoutSelection
              onReset={onCustomLayoutReset}
              roomState={selectedRooms}
            />
          )}
          {!isCustomLayoutSelected &&
            RoomListDefinitions.map((rld) => (
              <RoomListItem
                selected={selectedLayout?.title === rld.title}
                onSelect={() => {
                  setSelectedLayout(rld);
                  onSelect(rld);
                }}
                key={rld.title}
                mode={cardPropertyLayoutMode}
                {...rld}
              />
            ))}
          <div className="card-controls">
            <div>{t("homeContainer.propertyLayoutNumberOfRooms")}</div>
            <button
              className={`Button withIcon ${
                isCustomLayoutSelected ? "Button--Activated" : ""
              } `}
              type="button"
              onClick={toggleLayoutModal}
            >
              <img src={HouseIcon} alt="" />
              <span>{t("homeContainer.propertyLayoutOwnLayout")}</span>
            </button>
          </div>
        </div>
      </Card>
      <RoomModal
        isOpen={isLayoutModalOpen}
        onRequestClose={toggleLayoutModal}
        onValidate={handleModalConfirmation}
      />
    </>
  );
};

export default PropertyLayoutStep;
