import { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";
import StepContainer from "../StepContainer";
import Card from "../Card";
import PropertyLayoutStep from "./components/PropertyLayoutStep";
import { DeviceTypes } from "../../shared/types";
import { RoomListDefinition } from "./components/PropertyLayoutStep/types";

const PropertyTypes = {
  home: "home",
  apartment: "apartment",
};

enum PropertType {
  home = "home",
  apartment = "apartment",
}

interface HomeContainerProps {
  step: number;
  setNextStep: () => void;
  setPreviousStep: () => void;
  setDeviceTypes: (deviceTypes: DeviceTypes[]) => void;
  setPropertType: (propertyType: PropertType) => void;
  setLayoutType: (layoutType: string) => void;
}

const HomeContainer: FC<HomeContainerProps> = ({
  step,
  setNextStep,
  setDeviceTypes,
  setPropertType,
  setLayoutType,
}) => {
  const [t] = useTranslation();
  const [propertyType, setPropertyType] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedDeviceTypes, setSelectedDeviceTypes] = useState<DeviceTypes[]>(
    []
  );

  const handlePropertyTypeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentStep(currentStep + 1);
    setPropertyType(e.target.value);
    setPropertType(e.target.value as PropertType);
  };

  const toggleDeviceType = (key: DeviceTypes) => {
    return () => {
      const index = selectedDeviceTypes.indexOf(key);
      if (index >= 0) {
        selectedDeviceTypes.splice(index, 1);
      } else {
        selectedDeviceTypes.push(key);
      }

      setSelectedDeviceTypes([...selectedDeviceTypes]);
      setDeviceTypes([...selectedDeviceTypes]);
    };
  };

  const handleNext = () => {
    return () => {
      if (currentStep === 2) {
        setNextStep();
      }
      setCurrentStep(currentStep + 1);
    };
  };

  const handleBack = () => {
    if (!currentStep) {
      return undefined;
    }

    return () => {
      setCurrentStep(currentStep - 1);
    };
  };

  const canProgress =
    (currentStep === 0 && !!propertyType) ||
    (currentStep === 1 && true) ||
    (currentStep === 2 && !!selectedDeviceTypes.length);

  const confirmLayoutSelection = (roomState: RoomListDefinition) => {
    setLayoutType(roomState.title);
    setCurrentStep(currentStep + 1);
  };

  return (
    <StepContainer
      canProgress={canProgress}
      onBack={handleBack()}
      onNext={handleNext()}
    >
      <div className="HomeContainer">
        <Card
          disabled={currentStep !== 0}
          title={t("homeContainer.choosePropertyType")}
        >
          <div className="property-type">
            <div className="card-radio-container">
              <label
                className={`card-radio-container-item home ${
                  propertyType === PropertyTypes.home ? "checked" : ""
                }`}
              >
                <span>{t("homeContainer.propertyTypeHome")}</span>
                <input
                  onChange={handlePropertyTypeChange}
                  type="radio"
                  name="propertyType"
                  value="home"
                />
              </label>
              <label
                className={`card-radio-container-item apartment  ${
                  propertyType === PropertyTypes.apartment ? "checked" : ""
                }`}
              >
                <span>{t("homeContainer.propertyTypeApartment")}</span>
                <input
                  onChange={handlePropertyTypeChange}
                  type="radio"
                  name="propertyType"
                  value="apartment"
                />
              </label>
            </div>
          </div>
        </Card>
        <PropertyLayoutStep
          disabled={currentStep !== 1}
          onSelect={confirmLayoutSelection}
        />
        <Card
          disabled={currentStep !== 2}
          title={t("homeContainer.chooseDeviceType")}
        >
          <div className="card-checkbox-container">
            <div
              onClick={toggleDeviceType(DeviceTypes.LIGHTNING)}
              className={`card-checkbox-container-item lightning ${
                selectedDeviceTypes.indexOf(DeviceTypes.LIGHTNING) >= 0
                  ? "checked"
                  : ""
              }`}
            >
              {t("homeContainer.deviceType.Lightning")}
            </div>
            <div
              onClick={toggleDeviceType(DeviceTypes.SHUTTER)}
              className={`card-checkbox-container-item shutter ${
                selectedDeviceTypes.indexOf(DeviceTypes.SHUTTER) >= 0
                  ? "checked"
                  : ""
              }`}
            >
              {t("homeContainer.deviceType.Shutter")}
            </div>
            <div
              onClick={toggleDeviceType(DeviceTypes.HEATING)}
              className={`card-checkbox-container-item heating ${
                selectedDeviceTypes.indexOf(DeviceTypes.HEATING) >= 0
                  ? "checked"
                  : ""
              }`}
            >
              {t("homeContainer.deviceType.Heating")}
            </div>
          </div>
        </Card>
      </div>
    </StepContainer>
  );
};

export default HomeContainer;
