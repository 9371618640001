import { useState, useReducer } from "react";
import "./App.scss";
import "./shared/styles/buttons.scss";
import {
  AppHeader,
  Stepper,
  HomeContainer,
  QuizContainer,
  BOMContainer,
} from "./components";

import AppContext from "./context/AppContext";
import appReducer, { ActionKind } from "./context/reducer";
import { DeviceTypes } from "./shared/types";

function App() {
  const [activeStep, setStep] = useState(0);
  const [appState, dispatch] = useReducer(appReducer, {
    deviceTypes: [],
    results: [],
    propertyType: "",
    activeDeviceType: "",
  });

  const setNextActiveDeviceType = () => {
    dispatch({
      type: ActionKind.SetNextActiveDevice,
    });
  };

  const setDeviceTypes = (deviceTypes: DeviceTypes[]) => {
    dispatch({
      type: ActionKind.SetDeviceTypes,
      payload: deviceTypes,
    });
  };

  const pushSelection = (result: any) => {
    dispatch({
      type: ActionKind.PushResult,
      payload: result,
    });
  };

  const setPropertType = (type: any) => {
    dispatch({
      type: ActionKind.SetPropertType,
      payload: type,
    });
  };

  const setLayouType = (type: string) => {
    dispatch({
      type: ActionKind.SetLayoutType,
      payload: type,
    });
  };

  return (
    <div className="App">
      <AppContext.Provider value={appState}>
        <AppHeader />
        <Stepper activeStep={activeStep} />
        {activeStep === 0 && (
          <HomeContainer
            step={activeStep}
            setNextStep={() => setStep(activeStep + 1)}
            setPreviousStep={() => setStep(activeStep - 1)}
            setDeviceTypes={setDeviceTypes}
            setPropertType={setPropertType}
            setLayoutType={setLayouType}
          />
        )}
        {activeStep === 1 && (
          <QuizContainer
            step={activeStep}
            setNextStep={() => setStep(activeStep + 1)}
            setPreviousStep={() => setStep(activeStep - 1)}
            deviceTypes={appState.deviceTypes}
            setDeviceTypes={setDeviceTypes}
            pushSelection={pushSelection}
            setNextActivedeviceType={setNextActiveDeviceType}
          />
        )}
        {activeStep === 2 && (
          <BOMContainer
            step={activeStep}
            setNextStep={() => setStep(activeStep + 1)}
            setPreviousStep={() => setStep(activeStep - 1)}
            results={appState.results}
          />
        )}
      </AppContext.Provider>
    </div>
  );
}

export default App;
