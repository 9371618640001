import React, { FC } from "react";
import { QuestionDefinition } from "../../../../../../shared/types";
import "./styles.scss";

interface Props {
  answer: string;
  handleAnswer: (option: string) => void;
  options: string[];
  definition: QuestionDefinition;
}

const TileSelect: FC<Props> = ({ answer, handleAnswer, options,definition }) => {
  return (
    <div className={`TileSelect TileSelect--${definition.tile_type}`}>
      {options.map((option) => {
        return (
          <div
            className={`TileSelect__Option ${
              option === answer ? "TileSelect__Option--Active" : ""
            }`}
            style={{backgroundImage: `url('${definition.tile_image[option]}')`}}
            onClick={() => handleAnswer(option)}
          >
            {option}
          </div>
        );
      })}
    </div>
  );
};

export default TileSelect;
