import { createContext } from "react";

const initialState = {
  deviceTypes: [],
  layoutType: "",
  propertyType: "",
  toggleDeviceType: (deviceType: string) => {},
  results: [],
  pushSelection: () => {},
};

const AppContext = createContext(initialState);
AppContext.displayName = "AppContext";

export default AppContext;
