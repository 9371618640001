import React, { FC } from 'react'
import './styles.scss'

interface Props {
  answer: string
  handleAnswer: (option: string) => void
  options: string[]
}

const SimpleSelect: FC<Props> = ({ answer, handleAnswer, options }) => {
  return (
    <div className="SimpleSelect">
      {options.map((option) => {
        return (
          <div
            className={`SimpleSelect__Option ${
              option === answer ? 'SimpleSelect__Option--Active' : ''
            }`}
            onClick={() => handleAnswer(option)}
          >
            {option}
          </div>
        );
      })}
    </div>
  )
}

export default SimpleSelect
