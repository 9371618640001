import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RoomListTypes } from '../../../../../../models';
import { RoomListDefinition } from '../../types'

import './styles.scss';

interface RoomListItemProps extends RoomListDefinition {
  mode: RoomListTypes
  onSelect: () => void
  selected: boolean
}

const RoomListItem: FC<RoomListItemProps> = ({
  title,
  subtitle,
  mode,
  bedrooms,
  bathrooms,
  cuisines,
  toilet,
  livingRoom,
  homeSolution,
  onSelect,
  selected
}) => {
  const [t] = useTranslation();
  return (
    <div
      className={`RoomListItem ${
          mode === RoomListTypes.DEFAULT ? 'RoomListItem--Default' : 'RoomListItem--Details'
        } ${
          selected ? 'RoomListItem--Selected' : ''
        }`
      }
      onClick={() => onSelect()}
    >
      {mode === RoomListTypes.DEFAULT && (
        <>
          <div className="RoomListItemContent ">
            <div className="RoomListItemTitle">{t(title)}</div>
            <div className="RoomListItemSubTitle">{t(subtitle)}</div>
          </div>
          <div className="RoomListItemRoomCount">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="42"
                viewBox="0 0 50 42"
              >
                <text
                  id="segment_hotels"
                  transform="translate(0 39)"
                  fill="#fff"
                  fontSize="50"
                  fontFamily="se-icon"
                >
                  <tspan x="0" y="0">
                    segment_hotels
                  </tspan>
                </text>
              </svg>
            </div>
            <div className="RoomListItemRoomCountIndicator">{`x ${bedrooms}`}</div>
          </div>
        </>
      )}
      {mode === RoomListTypes.DETAILS && (
        <>
          <div className="RoomListItemContent ">
            <div className="RoomListItemType">{t(title)}-</div>
            <div className="RoomListItemRoomDetails">
              {`
                ${
                  bedrooms > 0
                    ? `${bedrooms} ${t('shared.roomTypes.bedroom')}, `
                    : ''
                }
                ${bathrooms} ${t('shared.roomTypes.bathroom')},
                ${cuisines} ${t('shared.roomTypes.cuisines')},
                ${toilet} ${t('shared.roomTypes.toilet')},
                ${livingRoom} ${t('shared.roomTypes.livingRoom')},
                ${homeSolution} ${t('shared.roomTypes.homeSolution')}
              `}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RoomListItem;
