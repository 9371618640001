import './styles.scss';
interface StepItemProps {
    title: string,
    id: number,
    isActive: boolean,
};

const StepItem = ({id, title, isActive}:StepItemProps) => {
    return (
        <div className={`StepItem ${isActive ? 'active': ''}`}>
            <div className="StepItem-Id">{id + 1}</div>
            <div className="StepItem-Label">{title}</div>
        </div>
    );
};

export default StepItem;